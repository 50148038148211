<template>
  <CRow class="m-0 p-0 pb-2 mb-2 iframe-container" v-bind:style="{ height: size }">
    <!--<iframe v-show="!darkMode" -->

    <!--CCol col=12>
              <VueFriendlyIframe
              style="min-height:800px;height:100vh"
   :src="urlPath"
   
   @load="onLoad"
   height="900"
   width="400"
   sandbox="allow-same-origin allow-scripts"
   class="embed-iframe embed-responsive embed-responsive-item"
   
   ></VueFriendlyIframe>
     </CCol-->
    <CCol col="12" class="m-0 p-0">
      <ResizableIFrameVue :v-show="show" v-bind:style="{ height: size }" ref="grafana"
        class="m-0 p-0  embed-iframe embed-responsive embed-responsive-item " marginwidth="0" marginheight="0"
        frameborder="0" scrolling="no" width="100%" :height="size" :src="urlPath"
        referrerpolicy="origin-when-cross-origin" sandbox="allow-same-origin allow-scripts" @load="onIframeLoad()">
      </ResizableIFrameVue>
      <!--iframe :v-show="show"
     v-bind:style="{height: size}"
      ref="grafana"
      class="m-0 p-0  embed-iframe embed-responsive embed-responsive-item "
      marginwidth="0"
      marginheight="0"
      frameborder="0"
      scrolling="no"
      width="100%"
      :height="size"
      :src="urlPath"
      referrerpolicy="origin-when-cross-origin"
      sandbox="allow-same-origin allow-scripts allow-forms"
      @load="onIframeLoad()"
      
      
    >
    </!--iframe-->
    </CCol>
    <!--iframe v-show="darkMode"
      ref="grafana"
      class="m-0 p-0  embed-iframe embed-responsive embed-responsive-item "
      marginwidth="0"
      marginheight="0"
      frameborder="0"
      scrolling="no"
      width="100%"
      height="800"
      :src="urlPathDark"
      referrerpolicy="origin-when-cross-origin"
      @load="onIframeLoad()"
      seamless
      loading="lazy"
    >
    </iframe-->


  </CRow>
</template>

<style>
.iframe-container {
  min-height: 1024px;
}

/** Class embed-iframe embed-responsive embed-responsive-item */
.embed-iframe {

  /*height: 100%;*/
  /*display: block;*/
  padding: 0;
  overflow: hidden;
  overflow-y: hidden;
  /* Hide vertical scrollbar */


  /*
  position: relative;
  bottom: 0;
  top:0;
  left: 0;
  right: 0;
  */
}

.embed-iframe::-webkit-scrollbar {
  display: none;
}

.box-title {
  text-align: center;
  font-size: 16px;
  float: center;
  width: 80%;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
</style>



<script>
//import VueFriendlyIframe from "../base/VueFriendlyIFrame";
import ResizableIFrameVue from "../base/ResizableIFrame";

export default {
  name: "GrafanaEmbeddedDashboard",

  components: {
    //VueFriendlyIframe,
    ResizableIFrameVue
  },
  props: {
    selected: Object,
    refresh: String,
    from: String | Number,
    to: String | Number,
    orgId: String,
    truckId: String,
    mode: Boolean,
    annotations: {
      type: String || Array,
      default: "0",
    },
    kiosk: { type: String, default: "" },

    show: { type: Boolean, default: true },
    size: {
      type: Number, 
      default: 1200,
    },
    src: {
      type: String

    }


  },

  data() {
    return {
      
      loaded: false,
      edit: false,
      //show: true, // Use to toggle IFrame View on activated / deactivated instead of destroying all the time 
      grafanaAppDOM: undefined, // For When we capture the Grafana App,
      //selected: null,
    };
  },
  mounted() {
    if (this.$refs.grafana) {
      let window = this.$refs.grafana.contentWindow;
      try{
      window.addEventListener("message", (event) => {
        console.log(event);

      }, false);
    }
    catch(err){
      console.log(err); 
    }
    }
  },
  beforeDestroy() {
    this.removeKeyPressBlocker();
  },
  computed: {
    device() { return this.selected ? this.selected.device : undefined }, // TODO: Device is a common variable to use. 
    darkMode() {
      return this.$store.state.darkMode;
    },
    urlPathDark() {
      let temp = "";
      if (!this.src) {
        // TODO: Display a Default Dashboard !!

        return ""; // Don't serve anything!
      } else
        temp = this.encodeUrl(this.src, true);
      return temp;
    },
    urlPath() {
      //return "/pages/rules.html"
      let temp = "";
      if (!this.src) {
        // TODO: Display a Default Dashboard !!

        return ""; // Don't serve anything!
      } else

        //temp = this.encodeUrl(this.src, this.darkMode);
        temp = this.encodeUrl(this.src, this.mode);

      return temp;
    }
  },
  methods: {

    encodeUrl(src, mode) {
      // HACK for DEMO ONLY !
      let temp = src;
      if (this.from) {
        temp += "&from=" + this.from;
      }
      if (this.to) {
        temp += "&to=" + this.to;
      }
      if (!this.kiosk) {
        temp += "&kiosk=1"; // Enter Kiosk Mode
        this.edit = false;
      } else if (this.kiosk === "tv") {
        temp += "&kiosk=tv"; // Always display in tv mode
      }

      if (this.refresh) {
        temp += "&refresh=" + this.refresh;
      }
      // Last item is to append the dark mode api 
      if (mode) temp += "&theme=dark";
      else temp += "&theme=light";

      if (this.orgId) {
        temp += `&orgId=${this.orgId}`;
      }

      if (this.annotations) {
        if (typeof (this.annotations) === "string") {
          temp += `&var-annotation=${this.annotations}`
        }
        else if (Array.isArray(this.annotations)) {
          for (var annotation of this.annotations)
            temp += `&var-annotation=${annotation}`
        }

      }
      if (this.truckId) {
        temp += `&var-TruckID=${this.truckId}`
      }
      if (this.device) {
        temp += `&var-deviceID=${this.device}`
      }

      return temp;
    },
    handleKeydown(event) {
      switch (event.keyCode) {
        case 27: // Escape Key
        case 70: // Search Key
          event.preventDefault();
          event.stopPropagation();
          return false;
          break;
        default:
          if (!this.edit) {
            event.preventDefault();
            event.stopPropagation();
          }
          return this.edit;
          break;
      }
    },
    resizeIFrame() {
      try {

        let iFrame = this.$refs.grafana;
        let document = iFrame.contentWindow.document;
        //this.size = 1900;

        //console.log(iFrame.contentWindow.document.body);
        // Log what we started with
        //console.log(document.body.scrollWidth);
        //console.log(document.body.scrollHeight);


        /// Default action is to Set hight to scroll height
        //iFrame.height = document.body.scrollHeight; // This only works if served from same domain!!!

        //this.grafanaAppDOM = document.querySelector(".grafana-app");

        //console.log(this.grafanaAppDOM);
        //div.dashboard-content -> [0].children[3].children[0].childNodes[0].firstChild.children[1].childNodes[0].childNodes[0].childNodes[0]
        // Parent = div.view
        // This drives the content height. to display
        // Note. Style dictates height = 1778px to start; 

        // Finally Grab the layout height to eliminate the annoying scroll. 
        let dashContent = document.querySelectorAll(".dashboard-content");
        console.log(dashContent);

        return;

        if (dashContent && dashContent.length > 0) {


          let newHeight = dashContent[0].children[0].scrollHeight; // React Grid is the actual height of the content
          if (dashContent[0].parentElement.style.overflow != "hidden")  // The parent element contains a scrollbar
          {
            // We actually need to disable scroll on the react grid! We 
            dashContent[0].parentElement.style.overflow = "hidden"
            //iFrame.height = dashContent[0].scrollHeight; 
            //iFrame.height = newHeight; 
            this.size = "height:" + newHeight + "px";
          }
          this.size = "height:" + newHeight + "px"; // Note: We get better results by growing the Container

          //iFrame.height = dashContent[0].scrollHeight; // this will grow the iframe. 

          //dashContent[0].classList.add('stop-scrolling'); 
          //dashContent[0].ownerDocument.body.classList.add('stop-scrolling'); 
          //dashContent[0].parentElement.classList.add('stop-scrolling'); 
          //dashContent[0].parentElement.style.overflow="hidden"; 
          //dashContent[0].children[0].classList.add('stop-scrolling'); 
        }

        //console.log(iFrame.height); 

      } catch (err) {
        console.log("Failed to Set IFrame Height. " + err);
      }
    },

    addContentResizeListener(e) {
      try{
      this.$refs.grafana.contentWindow.addEventListener(
        "message",
        (e) => {
          console.log(e);
        },

        true
      );
      }
      catch(err){
        console.log(err); 
      }
    },

    addKeyPressBlocker() {
      if (this.$refs.grafana) {

        try {
          if (this.$refs.grafana.contentWindow.Mousetrap) {
            this.$refs.grafana.contentWindow.Mousetrap.unbindGlobal("esc");
            this.$refs.grafana.contentWindow.Mousetrap.unbind("esc");
          }
        }
        catch (err) {
          console.log("Could not modify Moustrap Events");
        }


        this.$refs.grafana.contentWindow.addEventListener(
          "keydown",
          this.handleKeydown,
          true
        );
      }
    },
    removeKeyPressBlocker() {
      try {
        if (
          !this.$refs.grafana ||
          this.loaded == false ||
          this.$refs.grafana.contentWindow
        )
          return;
        this.$refs.grafana.contentWindow.removeEventListener(
          "keydown",
          this.handleKeydown,
          true
        );
      } catch (err) {
        console.log(err);
      }
    },
    onFrameReady(e) {
      try {
        this.loaded = true;
        this.resizeIFrame();
      } catch (err) {
        console.log(err);
      }

      this.$emit("load", e); // Emit this event so others can subscribe 

    },
    onIframeLoad() {
      try {
        console.log("Frame Loaded");
        this.addKeyPressBlocker();
        this.addContentResizeListener();
        //this.onFrameReady(); 
        setTimeout(this.onFrameReady, 250); //TODO: This is a hack!!!. Dont put busy waits unless we absolutely need to
        // Note, there is a Slight delay between frame loaded and first paint, We need another way!!!
        //this.resizeIFrame();
      } catch (err) {
        console.log("failed to add Key press Blocker! " + err);
      }
    },
    onLoad() {
      console.log("Frame Loaded");
      this.resizeIFrame();
    }
  }
};
</script>