var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    {
      staticClass: "m-0 p-0 pb-2 mb-2 iframe-container",
      style: { height: _vm.size }
    },
    [
      _c(
        "CCol",
        { staticClass: "m-0 p-0", attrs: { col: "12" } },
        [
          _c("ResizableIFrameVue", {
            ref: "grafana",
            staticClass:
              "m-0 p-0  embed-iframe embed-responsive embed-responsive-item ",
            style: { height: _vm.size },
            attrs: {
              "v-show": _vm.show,
              marginwidth: "0",
              marginheight: "0",
              frameborder: "0",
              scrolling: "no",
              width: "100%",
              height: _vm.size,
              src: _vm.urlPath,
              referrerpolicy: "origin-when-cross-origin",
              sandbox: "allow-same-origin allow-scripts"
            },
            on: {
              load: function($event) {
                return _vm.onIframeLoad()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }